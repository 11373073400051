import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  TextField,
  FormHelperText,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const validationSchema = Yup.object({
  reason: Yup.string(),
  bann: Yup.bool(),
  bannreason: Yup.string().when('bann', {
    is: true,
    then: Yup.string().required('Bitt einen Grund angeben.'),
    otherwise: Yup.string(),
  }),
})

function DialogDeclineForm({ onSubmit }) {
  const { handleChange, handleBlur, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        message: '',
        reason: '',
        bann: false,
      },
      validationSchema,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit(values) {
        if (onSubmit) onSubmit(values)
      },
    })

  return (
    <form>
      <FormHelperText>
        <FormattedMessage id="dialog.decline.helptext" />
      </FormHelperText>
      <TextField
        id="reason-input"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        name="reason"
        label="Grund der Ablehnung"
        helperText={touched.reason && errors.reason ? errors.reason : null}
        error={touched.reason && !!errors.reason}
        value={values.reason}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Box mb={4} />
      {values.bann && (
        <>
          <FormHelperText>
            <FormattedMessage id="dialog.decline.bann.helptext" />
          </FormHelperText>
          <TextField
            id="bannreason-input"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            name="bannreason"
            label="Grund der Sperrung"
            helperText={
              touched.bannreason && errors.bannreason ? errors.bannreason : null
            }
            error={touched.bannreason && !!errors.bannreason}
            value={values.bannreason}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={values.bann}
            onChange={handleChange}
            onBlur={handleBlur}
            name="bann"
          />
        }
        label="Autor sperren"
      />
      <Box width="100%" textAlign="right">
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.send" />
        </Button>
      </Box>
    </form>
  )
}

DialogDeclineForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default DialogDeclineForm
