import React from 'react'
import PropTypes from 'prop-types'
import useDialog from '@hooks/useDialog'
import { makeStyles } from '@material-ui/core/styles'

import { FormattedMessage } from 'react-intl'

import { DialogContent, DialogTitle } from '@material-ui/core'

import DialogNewEditorForm from '@components/forms/dialogneweditor'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(14),
    marginBottom: theme.spacing(4),
  },
}))

function NewEditorDialog({ onSubmit }) {
  const dial = useDialog()
  const classes = useStyles()

  function submit(values) {
    if (onSubmit) onSubmit(values)
    dial.closeDialog()
  }
  return (
    <>
      <DialogTitle id="responsive-neweditor-title" className={classes.title}>
        <FormattedMessage id="dialog.neweditor.title" />
      </DialogTitle>
      <DialogContent>
        <DialogNewEditorForm onSubmit={submit} />
      </DialogContent>
    </>
  )
}

NewEditorDialog.propTypes = {
  onSubmit: PropTypes.func,
}

export default NewEditorDialog
