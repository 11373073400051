import apiClient from './basicapi'

const apiRoot = 'survey/admin/'

export function getSurveyUsers() {
  return apiClient.get(`${apiRoot}SurveyUsers`).then((res) => res.data)
}

export function postSurveyUsers(recipient) {
  return apiClient.post(`${apiRoot}SurveyUsers`, recipient)
}

export function deleteSurveyUsers(id) {
  return apiClient.delete(`${apiRoot}SurveyUsers/${id}`)
}
