const { createMuiTheme } = require('@material-ui/core/styles')

const Breakpoints = require('./breakpoints')
const Colors = require('./colors')
const Container = require('./container')
const FontSize = require('./definitions/fontSize')
const Typography = require('./typography')
const Overrides = require('./overrides')

module.exports = function () {
  const theme = createMuiTheme({
    breakpoints: Breakpoints,
    palette: Colors,
    spacing: 4,
    typography: {
      fontSize: FontSize,
      fontFamily: 'BundesSans, Helvetica Arial, sans-serif',
    },
    overrides: Overrides,
    container: Container,
  })
  const typography = Typography(theme)
  const overrides = Overrides(theme)

  theme.typography = { ...theme.typography, ...typography }
  theme.overrides = { ...theme.overrides, ...overrides }

  theme.themeName = '5G Dialogplattform'

  return theme
}
