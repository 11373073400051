import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { ExitToAppRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import useAuth from '@hooks/useAuth'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.white,
    fontSize: '28px',
  },
}))

export default function LogoutButton() {
  const classes = useStyles()
  const { LoggingOut } = useAuth()

  return (
    <Tooltip arrow title={'Logout'}>
      <IconButton onClick={() => LoggingOut()}>
        <ExitToAppRounded className={classes.icon} />
      </IconButton>
    </Tooltip>
  )
}
