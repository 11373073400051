import React, { useEffect, useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Loading from '@components/loading'
import { verifyUserToken, loggingIn } from '@services/authservice'
import { setAuthHeader } from '@services/basicapi'

import useSnackbar from '@hooks/useSnackbar'

const AuthContext = createContext()

// const ErrorMessageMap = {
//   'auth/user-not-found': 'Diese Mail Adresse hat hier keinen Account :(',
// }

export function AuthProvider({ children, path }) {
  const { toggleSnackbar } = useSnackbar()
  const [tokenVeryfied, setTokenVeryfied] = useState(false)

  useEffect(() => {
    verifyUserToken()
      .then((token) => {
        if (!!!token) {
          navigate('/login/')
          if (!path.includes('/login/'))
            toggleSnackbar('Bitte loggen Sie sich ein.', 'warning')
        } else {
          setAuthHeader(token)
          setTokenVeryfied(true)
          if (path.includes('/login/')) navigate('/')
        }
      })
      .catch((e) => {
        console.error(e)
        navigate('/login/')
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }, [path])

  async function LoggingIn(email, password) {
    loggingIn(email, password)
      .then((res) => {
        localStorage.setItem('accessToken', res.data.accessToken)
      })
      .then(() => navigate('/'))
      .catch((e) => {
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  function LoggingOut() {
    localStorage.removeItem('accessToken')
    window.location.reload()
  }

  function switchLoading() {
    return typeof window === 'undefined' ||
      (!tokenVeryfied && !path.includes('/login/')) ? (
      <Loading />
    ) : (
      children
    )
  }
  return (
    <AuthContext.Provider value={{ LoggingIn, LoggingOut }}>
      {switchLoading()}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string.isRequired,
}

export default AuthContext
