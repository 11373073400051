// const BP = require('./breakpoints')
const FontSize = require('./definitions/fontSize')

module.exports = function (theme) {
  return {
    body1: {
      lineHeight: 1.75,
    },
    body2: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['baseXS'],
      lineHeight: 1.625,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['base'],
      },
    },
    h1: {
      fontSize: FontSize['4xl'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['6xl'],
      },
    },
    h2: {
      fontSize: FontSize['3xl'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['5xl'],
      },
    },
    h3: {
      fontSize: FontSize['xl'],
      lineHeight: 1.2,
      fontWeight: 500,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['3xl'],
      },
    },
    h4: {
      fontSize: FontSize['2lg'],
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['xl'],
      },
    },
    h5: {
      fontSize: FontSize['lg'],
      lineHeight: 1.2,
      fontWeight: 500,
    },
    intro: {
      fontSize: FontSize['3xl'],
      lineHeight: 1.286,
    },
    quote: {
      fontSize: FontSize['2xl'],
      lineHeight: 1.385,
      color: theme.palette.red.main,
    },
    teaser: {
      fontSize: FontSize['sm'],
      lineHeight: 1.57,
    },
    info: {
      lineHeight: 1.375,
      color: theme.palette.grey[300],
    },
    tag: {
      fontSize: FontSize['lg'],
      lineHeight: 1.3334,
      color: theme.palette.red.main,
      '&:hover': {
        color: theme.palette.red.light,
      },
    },
    navigation: {
      fontSize: FontSize['lg'],
      lineHeight: 1.3334,
    },
    button: {
      primary: {
        fontSize: FontSize['2lg'],
        fontWeight: 500,
      },
      secondary: {
        fontSize: FontSize['lg'],
      },
      text: {
        fontSize: 'inherit',
        fontWeight: 700,
      },
    },
  }
}
