import React from 'react'
import PropTypes from 'prop-types'
import useDialog from '@hooks/useDialog'
import { makeStyles } from '@material-ui/core/styles'

import { FormattedMessage } from 'react-intl'

import { DialogContent, DialogTitle } from '@material-ui/core'

import DialogDeclineForm from '@components/forms/dialogdecline'

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.primary.contrastText,
    paddingRight: theme.spacing(14),
    marginBottom: theme.spacing(4),
  },
}))

function DeclineDialog({ onSubmit }) {
  const dial = useDialog()
  const classes = useStyles()

  function submit(values) {
    if (onSubmit) onSubmit(values)
    dial.closeDialog()
  }
  return (
    <>
      <DialogTitle id="responsive-dialog-title" className={classes.title}>
        <FormattedMessage id="dialog.decline.title" />
      </DialogTitle>
      <DialogContent>
        <DialogDeclineForm onSubmit={submit} />
      </DialogContent>
    </>
  )
}

DeclineDialog.propTypes = {
  onSubmit: PropTypes.func,
}

export default DeclineDialog
