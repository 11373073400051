import apiClient from './basicapi'

const apiRoot = 'survey/admin/'

export function getNotificationRecipients() {
  return apiClient
    .get(`${apiRoot}NotificationRecipients`)
    .then((res) => res.data)
}

export function postNotificationRecipients(recipient) {
  return apiClient.post(`${apiRoot}NotificationRecipients`, recipient)
}

export function putNotificationRecipients(id, recipient) {
  return apiClient.put(`${apiRoot}NotificationRecipients/${id}`, recipient)
}

export function deleteNotificationRecipients(id) {
  return apiClient.delete(`${apiRoot}NotificationRecipients/${id}`)
}
