import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, TextField, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Autocomplete from '@material-ui/lab/Autocomplete'

const validationSchema = Yup.object({
  status: Yup.string().required('Bitte füllen Sie dieses Feld aus.'),
})

const predefinedItems = [
  {
    title: 'In Bearbeitung',
  },
  {
    title: 'In Klärung beim Kunden',
  },
  {
    title: 'Mögliche Sperrung',
  },
]

function DialogMarkForm({ onSubmit }) {
  const autocompleteref = useRef(null)
  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      status: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit(values) {
      if (onSubmit) onSubmit(values)
    },
  })

  return (
    <form>
      <Autocomplete
        id="mark-combo-box"
        freeSolo
        options={predefinedItems}
        getOptionLabel={(option) => option.title}
        onInputChange={(_, value) => {
          autocompleteref.current.value = value
          handleChange({ target: autocompleteref.current })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={autocompleteref}
            id="mark-input"
            name="status"
            label="Status"
            variant="outlined"
            helperText={errors.status ? errors.status : null}
            error={!!errors.status}
            value={values.status}
          />
        )}
      />
      <Box mt={4} width="100%" textAlign="right">
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.send" />
        </Button>
      </Box>
    </form>
  )
}

DialogMarkForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default DialogMarkForm
