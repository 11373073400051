import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, TextField, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import RichTextEditor from '@objects/richtext'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { amber, green, red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  draftButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
  deleteButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  publishButton: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))

const validationSchema = Yup.object({
  title: Yup.string().required('Bitte geben Sie einen Titel ein.'),
  message: Yup.string().required('Bitte geben Sie eine Antwort ein.'),
  editReason: Yup.string(),
})

function DialogAnswerForm({
  onSubmit,
  currentTitle,
  currentMsg,
  currentIsDraft,
  currentIsPublished,
  currentEditReason,
}) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [defaultMsg, setDefaultMsg] = useState('')
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      title: currentIsDraft && currentTitle ? currentTitle : '',
      message: currentIsDraft && currentMsg ? currentMsg : '',
      isDraft: currentIsDraft,
      shouldDelete: false,
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit(values) {
      if (onSubmit) onSubmit(values)
    },
  })
  const classes = useStyles()

  function handlePublish() {
    setFieldValue('isDraft', false)
    handleSubmit()
  }

  function handleDraft() {
    setFieldValue('isDraft', true)
    handleSubmit()
  }

  function handleDelete() {
    setFieldValue('shouldDelete', true)
    handleSubmit()
  }

  function rteChange(data) {
    const text = stateToHTML(data.getCurrentContent())
    if (values.message !== text) {
      setFieldValue('message', text)
    }
  }

  function rteBlur() {
    setFieldTouched('message', true)
  }

  function renderRTE() {
    let stringifiedRawState = null
    if (!isInitialized) {
      const blocksFromHTML = convertFromHTML(values.message)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      const rawState = convertToRaw(state)
      stringifiedRawState = JSON.stringify(rawState)
      setDefaultMsg(stringifiedRawState)
      setIsInitialized(true)
    }

    return (
      <RichTextEditor
        id="message-input"
        name="message"
        defaultValue={defaultMsg}
        onChange={rteChange}
        onBlur={rteBlur}
        error={touched.message && !!errors.message}
        errormessage={errors.message}
      />
    )
  }

  return (
    <form>
      <TextField
        id="title-input"
        fullWidth
        variant="outlined"
        name="title"
        label="Titel der Antwort"
        helperText={touched.title && errors.title ? errors.title : null}
        error={touched.title && !!errors.title}
        value={values.title}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Box mb={4} />
      {renderRTE()}
      {currentIsPublished && (
        <TextField
          className={classes.editReasonTextfield}
          id="editReason-input"
          fullWidth
          variant="outlined"
          name="editReason"
          label="Grund der Änderung"
          helperText={
            touched.editReason && errors.editReason ? errors.editReason : null
          }
          error={touched.editReason && !!errors.editReason}
          value={values.editReason}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
      <Box width="100%" textAlign="right">
        {currentIsDraft && !currentIsPublished && (
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            variant="contained"
            size="large"
          >
            <FormattedMessage id="button.draft.delete" />
          </Button>
        )}

        {!currentIsPublished && (
          <Button
            className={classes.draftButton}
            onClick={handleDraft}
            variant="contained"
            size="large"
          >
            <FormattedMessage id="button.draft" />
          </Button>
        )}

        <Button
          className={classes.publishButton}
          onClick={handlePublish}
          variant="contained"
          size="large"
        >
          <FormattedMessage id="button.publish" />
        </Button>
      </Box>
    </form>
  )
}

DialogAnswerForm.propTypes = {
  onSubmit: PropTypes.func,
  currentTitle: PropTypes.string,
  currentMsg: PropTypes.string,
  currentIsDraft: PropTypes.bool,
}

DialogAnswerForm.defaultProps = {
  currentTitle: null,
  currentMsg: null,
  currentIsDraft: false,
}

export default DialogAnswerForm
