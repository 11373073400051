const FontSize = require('../definitions/fontSize')

module.exports = function (theme) {
  return {
    root: {
      fontSize: FontSize['lg'],
      verticalAlign: 'middle',
    },
    fontSizeSmall: {
      fontSize: FontSize['baseXS'],
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['base'],
      },
    },
    fontSizeLarge: {
      fontSize: FontSize['3xl'],
    },
  }
}
