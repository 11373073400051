import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, TextField, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Bitte ein Passwort eintragen.')
    .min(8, 'Bitte mindestens 8 Zeichen eingeben.'),
})

function DialogEditorPwResetForm({ onSubmit }) {
  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit(values) {
      if (onSubmit) onSubmit(values)
    },
  })

  return (
    <form>
      <TextField
        id="password-input"
        fullWidth
        autoComplete="new-password"
        variant="outlined"
        name="password"
        type="password"
        label="Passwort"
        helperText={
          touched.password && errors.password ? errors.password : null
        }
        error={touched.password && !!errors.password}
        value={values.password}
        onChange={handleChange}
      />
      <Box width="100%" textAlign="right">
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.send" />
        </Button>
      </Box>
    </form>
  )
}

DialogEditorPwResetForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default DialogEditorPwResetForm
