import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, TextField, FormHelperText, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const validationSchema = Yup.object({
  reason: Yup.string().required('Bitt einen Grund angeben.'),
})

function DialogBannForm({ onSubmit }) {
  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit(values) {
      if (onSubmit) onSubmit(values)
    },
  })

  return (
    <form>
      <FormHelperText>
        <FormattedMessage id="dialog.bann.helptext" />
      </FormHelperText>
      <TextField
        id="reason-input"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        name="reason"
        label="Grund der Sperrung"
        helperText={errors.reason ? errors.reason : null}
        error={!!errors.reason}
        value={values.reason}
        onChange={handleChange}
      />
      <Box width="100%" textAlign="right">
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.send" />
        </Button>
      </Box>
    </form>
  )
}

DialogBannForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default DialogBannForm
