// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-beitraege-[articleid]-jsx": () => import("./../../../src/pages/beitraege/[articleid].jsx" /* webpackChunkName: "component---src-pages-beitraege-[articleid]-jsx" */),
  "component---src-pages-beitraege-auch-unbestaetigt-[articleid]-jsx": () => import("./../../../src/pages/beitraege-auch-unbestaetigt/[articleid].jsx" /* webpackChunkName: "component---src-pages-beitraege-auch-unbestaetigt-[articleid]-jsx" */),
  "component---src-pages-beitraege-jsx": () => import("./../../../src/pages/beitraege.jsx" /* webpackChunkName: "component---src-pages-beitraege-jsx" */),
  "component---src-pages-beitraegebenachrichtigungen-jsx": () => import("./../../../src/pages/beitraegebenachrichtigungen.jsx" /* webpackChunkName: "component---src-pages-beitraegebenachrichtigungen-jsx" */),
  "component---src-pages-bestellungen-jsx": () => import("./../../../src/pages/bestellungen.jsx" /* webpackChunkName: "component---src-pages-bestellungen-jsx" */),
  "component---src-pages-bestellungenemailsettings-jsx": () => import("./../../../src/pages/bestellungenemailsettings.jsx" /* webpackChunkName: "component---src-pages-bestellungenemailsettings-jsx" */),
  "component---src-pages-einstellungen-jsx": () => import("./../../../src/pages/einstellungen.jsx" /* webpackChunkName: "component---src-pages-einstellungen-jsx" */),
  "component---src-pages-entwuerfebeitraege-jsx": () => import("./../../../src/pages/entwuerfebeitraege.jsx" /* webpackChunkName: "component---src-pages-entwuerfebeitraege-jsx" */),
  "component---src-pages-gesperrtenutzer-jsx": () => import("./../../../src/pages/gesperrtenutzer.jsx" /* webpackChunkName: "component---src-pages-gesperrtenutzer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontaktanfragen-jsx": () => import("./../../../src/pages/kontaktanfragen.jsx" /* webpackChunkName: "component---src-pages-kontaktanfragen-jsx" */),
  "component---src-pages-kontaktbenachrichtigungen-jsx": () => import("./../../../src/pages/kontaktbenachrichtigungen.jsx" /* webpackChunkName: "component---src-pages-kontaktbenachrichtigungen-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-magazinantworten-jsx": () => import("./../../../src/pages/magazinantworten.jsx" /* webpackChunkName: "component---src-pages-magazinantworten-jsx" */),
  "component---src-pages-magazinentwuerfeantworten-jsx": () => import("./../../../src/pages/magazinentwuerfeantworten.jsx" /* webpackChunkName: "component---src-pages-magazinentwuerfeantworten-jsx" */),
  "component---src-pages-magazinmarkierteantworten-jsx": () => import("./../../../src/pages/magazinmarkierteantworten.jsx" /* webpackChunkName: "component---src-pages-magazinmarkierteantworten-jsx" */),
  "component---src-pages-markiertebeitraege-jsx": () => import("./../../../src/pages/markiertebeitraege.jsx" /* webpackChunkName: "component---src-pages-markiertebeitraege-jsx" */),
  "component---src-pages-neueantworten-jsx": () => import("./../../../src/pages/neueantworten.jsx" /* webpackChunkName: "component---src-pages-neueantworten-jsx" */),
  "component---src-pages-redakteure-jsx": () => import("./../../../src/pages/redakteure.jsx" /* webpackChunkName: "component---src-pages-redakteure-jsx" */),
  "component---src-pages-suche-jsx": () => import("./../../../src/pages/suche.jsx" /* webpackChunkName: "component---src-pages-suche-jsx" */),
  "component---src-pages-umfragebenachrichtigungen-jsx": () => import("./../../../src/pages/umfragebenachrichtigungen.jsx" /* webpackChunkName: "component---src-pages-umfragebenachrichtigungen-jsx" */),
  "component---src-pages-umfragen-jsx": () => import("./../../../src/pages/umfragen.jsx" /* webpackChunkName: "component---src-pages-umfragen-jsx" */),
  "component---src-pages-umfragenutzer-jsx": () => import("./../../../src/pages/umfragenutzer.jsx" /* webpackChunkName: "component---src-pages-umfragenutzer-jsx" */)
}

