import apiClient from './basicapi'

const apiRoot = 'printmaterial/admin/'

export function getOrders() {
  return apiClient.get(`${apiRoot}Orders`).then((res) => res.data)
}

export function deleteOrder(id) {
  return apiClient.delete(`${apiRoot}Orders/${id}`).then((res) => res.data)
}

export function getOrderNotificationRecipients() {
  return apiClient
    .get(`${apiRoot}NotificationRecipients`)
    .then((res) => res.data)
}

export function postOrderNotificationRecipients(recipient) {
  return apiClient.post(`${apiRoot}NotificationRecipients`, recipient)
}

export function putOrderNotificationRecipients(id, recipient) {
  return apiClient.put(`${apiRoot}NotificationRecipients/${id}`, recipient)
}

export function deleteOrderNotificationRecipients(id) {
  return apiClient.delete(`${apiRoot}NotificationRecipients/${id}`)
}
