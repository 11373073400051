/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

const React = require('react')
const ReactIntl = require('react-intl')
const Layout = require('./../src/components/layout/index').default

const { messages } = require('./../src/translations/index')
const { defaultLang } = require('./../src/translations/index')
const CssBaseline = require('@material-ui/core/CssBaseline').default
const { ThemeProvider } = require('@material-ui/core/styles')

const { SnackbarProvider } = require('../src/providers/SnackbarProvider')
const { DialogProvider } = require('../src/providers/DialogProvider')

const createTheme = require('../config/theme/index')
const muitheme = createTheme()

exports.wrapPage = ({ element, props }) => {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={muitheme}>
        <SnackbarProvider>
          <DialogProvider>
            <CssBaseline />
            <Layout {...props}>{element}</Layout>
          </DialogProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}
