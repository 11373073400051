import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { AuthProvider } from '@providers/AuthProvider'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import Navigation from '@components/navigation'
import LogoutButton from '@objects/logoutbutton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 0,
    minWidth: '100%',
  },
  content: {
    width: `calc(100% - 240px)`,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

function Layout({ children, pageContext, path, location }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AuthProvider path={path}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Link to="/">
              <Typography variant="h6">
                <FormattedMessage id="app.title">
                  {(title) => {
                    return `${title} - ${pageContext?.frontmatter?.title || ''}`
                  }}
                </FormattedMessage>
              </Typography>
            </Link>
            <LogoutButton />
          </Toolbar>
        </AppBar>
        {path !== '/login/' && <Navigation location={location} />}
        <main className={classes.content}>
          <Toolbar />
          {children}
        </main>
      </AuthProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  path: PropTypes.string.isRequired,
}

export default Layout
