import apiClient from './basicapi'

function verifyUserToken() {
  return new Promise((resolve, reject) => {
    return resolve(localStorage.getItem('accessToken'))
  })
}

function loggingIn(email, password) {
  return apiClient.post('editorauthorize', {
    email: email,
    password: password,
  })
}

export { verifyUserToken, loggingIn }
