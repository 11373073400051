import React from 'react'
import PropTypes from 'prop-types'
import useDialog from '@hooks/useDialog'
import { makeStyles } from '@material-ui/core/styles'

import { FormattedMessage } from 'react-intl'

import { DialogContent, DialogTitle } from '@material-ui/core'

import DialogAnswerForm from '@components/forms/dialoganswer'

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingRight: theme.spacing(14),
    marginBottom: theme.spacing(4),
  },
}))

function AnswerDialog({
  onSubmit,
  currentTitle,
  currentMsg,
  currentIsDraft,
  currentIsPublished,
  currentEditReason,
}) {
  const dial = useDialog()
  const classes = useStyles()

  function submit(values) {
    if (onSubmit) onSubmit(values)
    dial.closeDialog()
  }
  return (
    <>
      <DialogTitle id="responsive-answer-title" className={classes.title}>
        <FormattedMessage id="dialog.answer.title" />
      </DialogTitle>
      <DialogContent>
        <DialogAnswerForm
          onSubmit={submit}
          currentTitle={currentTitle}
          currentMsg={currentMsg}
          currentIsDraft={currentIsDraft}
          currentIsPublished={currentIsPublished}
          currentEditReason={currentEditReason}
        />
      </DialogContent>
    </>
  )
}

AnswerDialog.propTypes = {
  onSubmit: PropTypes.func,
  currentTitle: PropTypes.string,
  currentMsg: PropTypes.string,
  currentIsDraft: PropTypes.bool,
  currentEditReason: PropTypes.string,
}

export default AnswerDialog
