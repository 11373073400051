import React from 'react'
import PropTypes from 'prop-types'
import useDialog from '@hooks/useDialog'
import { makeStyles } from '@material-ui/core/styles'

import { FormattedMessage } from 'react-intl'

import { DialogContent, DialogTitle } from '@material-ui/core'

import DialogEditorPwResetForm from '@components/forms/dialogeditorpwresetform'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(14),
    marginBottom: theme.spacing(4),
  },
}))

function EditorPwResetDialog({ onSubmit }) {
  const dial = useDialog()
  const classes = useStyles()

  function submit(values) {
    if (onSubmit) onSubmit(values)
    dial.closeDialog()
  }
  return (
    <>
      <DialogTitle
        id="responsive-editorpwreset-title"
        className={classes.title}
      >
        <FormattedMessage id="dialog.editorpwreset.title" />
      </DialogTitle>
      <DialogContent>
        <DialogEditorPwResetForm onSubmit={submit} />
      </DialogContent>
    </>
  )
}

EditorPwResetDialog.propTypes = {
  onSubmit: PropTypes.func,
}

export default EditorPwResetDialog
