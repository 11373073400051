import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.GATSBY_FORUM_SERVICE_URL}api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
})

function setAuthHeader(accessToken) {
  apiClient.defaults.headers.common.authorization = `Bearer ${accessToken}`
}

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error.response ? error.response.status : 401
    if (status === 401) {
      localStorage.removeItem('accessToken')
      if (window) window.location.reload()
    }

    return Promise.reject(error)
  }
)

export default apiClient
export { setAuthHeader }
