import apiClient from './basicapi'

const apiRoot = 'forumsadmin/'

export function getDialogNotificationRecipients() {
  return apiClient.get(`${apiRoot}recipients`).then((res) => res.data)
}

export function postDialogNotificationRecipients(recipient) {
  return apiClient.post(`${apiRoot}recipients`, recipient)
}

export function putDialogNotificationRecipients(id, recipient) {
  return apiClient.put(`${apiRoot}recipients/${id}`, recipient)
}

export function deleteDialogNotificationRecipients(id) {
  return apiClient.delete(`${apiRoot}recipients/${id}`)
}
