import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { Dialog, IconButton } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import CustomDialog from '@components/dialog'

const DialogContext = createContext()

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.background.paper,
  },
}))

export function DialogProvider({ children }) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)
  const [dialogType, setDialogType] = useState(null)
  const [dialogProps, setDialogProps] = useState({})

  function openDialog(type, props = {}) {
    setDialogType(type)
    setDialogProps(props)
    setOpen(true)
  }

  function closeDialog(type = null) {
    setOpen(false)
    setDialogProps({})
  }

  return (
    <DialogContext.Provider value={{ open, openDialog, closeDialog }}>
      {children}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <CustomDialog type={dialogType} {...dialogProps} />
      </Dialog>
    </DialogContext.Provider>
  )
}

DialogProvider.propTypes = {
  children: PropTypes.any,
}

export default DialogContext
