const CssBaseline = require('./overrides/baseline')
const Icons = require('./overrides/icons')
const RichtextEditor = require('./overrides/richtexteditor')

module.exports = function (theme) {
  return {
    MuiCssBaseline: CssBaseline(theme),
    MuiSvgIcon: Icons(theme),
    MuiToolbar: {
      root: {
        width: '100%',
        maxWidth: theme.container.lg,
        marginLeft: '0px',
        marginRight: 'auto',
      },
    },
    MUIRichTextEditor: RichtextEditor(theme),
    MuiCardHeader: {
      title: {
        marginBottom: '0px',
      },
    },
    MuiCardContent: {
      root: {
        padding: theme.spacing(4, 3),
        '&:last-child': {
          paddingBottom: theme.spacing(8),
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '20px',
      },
    },
    MuiDialog: {
      paper: {
        minWidth: '40%',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(3),
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      },
    },
    MuiTablePagination: {
      toolbar: {
        marginLeft: 'auto',
        marginRight: 0,
      },
    },
  }
}
