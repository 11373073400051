import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, TextField, Button } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'

const validationSchema = Yup.object({
  name: Yup.string().required('Bitt einen Namen eingeben.'),
  email: Yup.string()
    .email('Diese E-Mail Adresse ist ungültig.')
    .required('Bitte E-Mail Adresse eingeben.'),
  password: Yup.string()
    .required('Bitte ein Passwort eintragen.')
    .min(8, 'Bitte mindestens 8 Zeichen eingeben.'),
})

function DialogNewEditorForm({ onSubmit }) {
  const intl = useIntl()
  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit(values) {
      if (onSubmit) onSubmit(values)
    },
  })

  return (
    <form>
      <TextField
        id="name-input"
        fullWidth
        variant="outlined"
        name="name"
        label="Name"
        helperText={touched.name && errors.name ? errors.name : null}
        error={touched.name && !!errors.name}
        value={values.name}
        onChange={handleChange}
      />
      <Box mb={4} />
      <TextField
        id="email-input"
        fullWidth
        autoComplete="new-password"
        variant="outlined"
        name="email"
        type="email"
        label="E-Mail Adresse"
        helperText={
          touched.email && errors.email
            ? errors.email
            : intl.formatMessage({
                id: 'dialog.neweditor.helptext',
              })
        }
        error={touched.email && !!errors.email}
        value={values.email}
        onChange={handleChange}
      />
      <Box mb={4} />
      <TextField
        id="password-input"
        fullWidth
        autoComplete="new-password"
        variant="outlined"
        name="password"
        type="password"
        label="Passwort"
        helperText={
          touched.password && errors.password ? errors.password : null
        }
        error={touched.password && !!errors.password}
        value={values.password}
        onChange={handleChange}
      />
      <Box width="100%" textAlign="right">
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.send" />
        </Button>
      </Box>
    </form>
  )
}

DialogNewEditorForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default DialogNewEditorForm
