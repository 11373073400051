import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

function DialogDeleteForm({ onSubmit, authorEmail }) {
  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const textFieldRef = useRef(null)

  function handleEmailValidation() {
    if (textFieldRef.current.value === authorEmail) {
      setIsEmailInvalid(false)
      onSubmit({ shouldDelete: true })
    } else {
      setIsEmailInvalid(true)
    }
  }

  return (
    <>
      <TextField
        id="title-input"
        fullWidth
        variant="outlined"
        label="E-Mail des Autors"
        inputRef={textFieldRef}
        helperText={isEmailInvalid ? 'Die E-Mail ist falsch' : null}
        error={isEmailInvalid}
      />
      <Box width="100%" textAlign="right">
        <Button
          onClick={handleEmailValidation}
          variant="contained"
          size="large"
          color="primary"
        >
          <FormattedMessage id="button.deleteAuthor" />
        </Button>
      </Box>
    </>
  )
}

DialogDeleteForm.propTypes = {
  onSubmit: PropTypes.func,
  authorEmail: PropTypes.string,
}

export default DialogDeleteForm
