import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {
  ChatBubbleRounded,
  ForumRounded,
  BookmarkRounded,
  BlockRounded,
  EditNotificationsRounded,
  GroupRounded,
  InboxRounded,
  SettingsRounded,
  MailRounded,
  DraftsRounded,
  ShoppingCartRounded,
  SearchRounded,
} from '@material-ui/icons'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  link: {
    transition: '.2s ease-in-out',
  },
  linkcurrent: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
}))

function Navigation({ location }) {
  const classes = useStyles()

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <Typography variant="h4" className={classes.title}>
              Dialog
            </Typography>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('/beitraege'),
              })}
              component={Link}
              to="/beitraege/"
            >
              <ListItemIcon>
                <ChatBubbleRounded />
              </ListItemIcon>
              <ListItemText primary="Beiträge" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('entwuerfebeitraege'),
              })}
              component={Link}
              to="/entwuerfebeitraege/"
            >
              <ListItemIcon>
                <DraftsRounded />
              </ListItemIcon>
              <ListItemText primary="Entwürfe" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('neueantworten'),
              })}
              component={Link}
              to="/neueantworten/"
            >
              <ListItemIcon>
                <ForumRounded />
              </ListItemIcon>
              <ListItemText primary="Neue Antworten" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('markiertebeitraege'),
              })}
              component={Link}
              to="/markiertebeitraege/"
            >
              <ListItemIcon>
                <BookmarkRounded />
              </ListItemIcon>
              <ListItemText primary="Markierte Beiträge" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes('suche'),
              })}
              component={Link}
              to="/suche/"
            >
              <ListItemIcon>
                <SearchRounded />
              </ListItemIcon>
              <ListItemText primary="Suche" />
            </ListItem>

            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="h4" className={classes.title}>
              Magazin
            </Typography>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('magazinantworten'),
              })}
              component={Link}
              to="/magazinantworten/"
            >
              <ListItemIcon>
                <ForumRounded />
              </ListItemIcon>
              <ListItemText primary="Antworten" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'magazinentwuerfeantworten'
                ),
              })}
              component={Link}
              to="/magazinentwuerfeantworten/"
            >
              <ListItemIcon>
                <ForumRounded />
              </ListItemIcon>
              <ListItemText primary="Entwürfe" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'magazinmarkierteantworten'
                ),
              })}
              component={Link}
              to="/magazinmarkierteantworten/"
            >
              <ListItemIcon>
                <BookmarkRounded />
              </ListItemIcon>
              <ListItemText primary="Markierte Antworten" />
            </ListItem>

            <Box my={1}>
              <Box mb={5} />
              <Divider />
            </Box>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'beitraegebenachrichtigungen'
                ),
              })}
              component={Link}
              to="/beitraegebenachrichtigungen/"
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary="Benachrichtigungen (Dialog und Magazin)" />
            </ListItem>

            <Box my={2}>
              <Divider />
            </Box>
            <Box mb={10} />
            <Typography variant="h4" className={classes.title}>
              Bestellungen
            </Typography>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('bestellungen/'),
              })}
              component={Link}
              to="/bestellungen/"
            >
              <ListItemIcon>
                <ShoppingCartRounded />
              </ListItemIcon>
              <ListItemText primary="Übersicht" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'bestellungenemailsettings'
                ),
              })}
              component={Link}
              to="/bestellungenemailsettings/"
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary="E-Mail Einstellungen" />
            </ListItem>

            <Box my={2}>
              <Divider />
            </Box>

            <Typography variant="h4" className={classes.title}>
              Umfragen
            </Typography>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('umfragen/'),
              })}
              component={Link}
              to="/umfragen/"
            >
              <ListItemIcon>
                <InboxRounded />
              </ListItemIcon>
              <ListItemText primary="Ausgefüllte Umfragen" />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'umfragebenachrichtigungen'
                ),
              })}
              component={Link}
              to="/umfragebenachrichtigungen/"
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary="Benachrichtigungen" />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('umfragenutzer'),
              })}
              component={Link}
              to="/umfragenutzer/"
            >
              <ListItemIcon>
                <GroupRounded />
              </ListItemIcon>
              <ListItemText primary="Umfrage-Nutzer" />
            </ListItem>

            <Box my={2}>
              <Divider />
            </Box>

            <Typography variant="h4" className={classes.title}>
              Kontaktanfragen
            </Typography>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('kontaktanfragen'),
              })}
              component={Link}
              to="/kontaktanfragen/"
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary="Kontaktanfragen" />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'kontaktbenachrichtigungen'
                ),
              })}
              component={Link}
              to="/kontaktbenachrichtigungen/"
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary="Benachrichtigungen" />
            </ListItem>

            <Box my={1}>
              <Box mb={30} />
              <Divider />
            </Box>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('gesperrtenutzer'),
              })}
              component={Link}
              to="/gesperrtenutzer/"
            >
              <ListItemIcon>
                <BlockRounded />
              </ListItemIcon>
              <ListItemText primary="gesperrte Nutzer" />
            </ListItem>
          </List>
          <Box my={1}>
            <Divider />
          </Box>
          <List>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('redakteure'),
              })}
              component={Link}
              to="/redakteure/"
            >
              <ListItemIcon>
                <GroupRounded />
              </ListItemIcon>
              <ListItemText primary="Redakteure" />
            </ListItem>
            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('einstellungen'),
              })}
              component={Link}
              to="/einstellungen/"
            >
              <ListItemIcon>
                <SettingsRounded />
              </ListItemIcon>
              <ListItemText primary="Einstellungen" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  )
}

Navigation.propTypes = {
  location: PropTypes.object,
}

export default Navigation
