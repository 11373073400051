//"title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "media", "numberList", "bulletList", "quote", "code", "clear", "save"
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MUIRichTextEditor from 'mui-rte'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  richtext: {
    position: 'relative',
    cursor: 'pointer',
    '& .MuiIconButton-root': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-of-type': {
        marginLeft: theme.spacing(2),
      },
      '&:not(:first-of-type)': {
        position: 'relative',
        '&:before': {
          content: '""',
          cursor: 'pointer',
          position: 'absolute',
          left: '-5px',
          top: '25%',
          height: '50%',
          width: '2px',
          backgroundColor: theme.palette.grey.light,
        },
      },
    },
  },
  chars: {
    position: 'absolute',
    right: '20px',
    bottom: '10px',
    color: theme.palette.grey.main,
    ...theme.typography.teaser,
  },
  errormessage: {
    color: theme.palette.text.contrast,
    backgroundColor: theme.palette.red.main,
    borderBottomLeftRadius: theme.spacing(6),
    borderBottomRightRadius: theme.spacing(6),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    width: '100%',
  },
  error: {
    '& > div:first-child': {
      borderColor: theme.palette.red.main,
      backgroundColor: theme.palette.red.light + '15',
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '& .MuiPaper-root': {
        color: theme.palette.red.main,
      },
      '& .MuiIconButton-root': {
        '&:not(:first-of-type)': {
          '&:before': {
            backgroundColor: theme.palette.red.main,
          },
        },
      },
    },
  },
}))

function RichTextEditor({
  className,
  onChange,
  error,
  errormessage,
  ...props
}) {
  const classes = useStyles()

  function rteChange(data) {
    if (onChange) onChange(data)
  }

  return (
    <div className={classNames(classes.richtext, { [classes.error]: error })}>
      <MUIRichTextEditor
        controls={['bold', 'italic', 'link', 'numberList', 'bulletList']}
        label="Schreiben Sie hier..."
        inlineToolbar={true}
        inlineToolbarControls={['bold', 'italic', 'link']}
        onChange={rteChange}
        {...props}
      />
      {error && errormessage && (
        <div className={classes.errormessage}>{errormessage}</div>
      )}
    </div>
  )
}

RichTextEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errormessage: PropTypes.string,
}
export default RichTextEditor
