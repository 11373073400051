import React from 'react'
import PropTypes from 'prop-types'

import DeclineDialog from './types/decline'
import BannDialog from './types/bann'
import MarkDialog from './types/mark'
import AnswerDialog from './types/answer'
import DeleteDialog from './types/delete'
import CommentDialog from './types/comment'
import NewEditorDialog from './types/neweditor'
import EditorPwResetDialog from './types/editorpwreset'
import EditOrderRecipientDialog from './types/editorderrecipient'
import AddOrderRecipientDialog from './types/addorderrecipient'
import EditNotificationRecipientDialog from './types/editnotificationrecipient'
import AddNotificationRecipientDialog from './types/addnotificationrecipient'
import AddSurveyUserDialog from './types/addsurveyuser'
import AddDialogRecipientDialog from './types/adddialogrecipient'
import EditDialogRecipientDialog from './types/editdialogrecipient/index'
import AddContactRecipientDialog from './types/addcontactrecipient'
import EditContactRecipientDialog from './types/editcontactrecipient/index'

function CustomDialog({ className, type, ...props }) {
  function renderDialog() {
    switch (type) {
      case 'decline':
        return <DeclineDialog {...props} />
      case 'bann':
        return <BannDialog {...props} />
      case 'mark':
        return <MarkDialog {...props} />
      case 'answer':
        return <AnswerDialog {...props} />
      case 'delete':
        return <DeleteDialog {...props} />
      case 'comment':
        return <CommentDialog {...props} />
      case 'neweditor':
        return <NewEditorDialog {...props} />
      case 'editorpwreset':
        return <EditorPwResetDialog {...props} />
      case 'addorderrecipient':
        return <AddOrderRecipientDialog {...props} />
      case 'editorderrecipient':
        return <EditOrderRecipientDialog {...props} />
      case 'addnotificationrecipient':
        return <AddNotificationRecipientDialog {...props} />
      case 'editnotificationrecipient':
        return <EditNotificationRecipientDialog {...props} />
      case 'addsurveyuser':
        return <AddSurveyUserDialog {...props} />
      case 'adddialogrecipient':
        return <AddDialogRecipientDialog {...props} />
      case 'editdialogrecipient':
        return <EditDialogRecipientDialog {...props} />
      case 'addcontactrecipient':
        return <AddContactRecipientDialog {...props} />
      case 'editcontactrecipient':
        return <EditContactRecipientDialog {...props} />
      default:
        return <div></div>
    }
  }

  return <>{renderDialog()}</>
}

CustomDialog.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

export default CustomDialog
