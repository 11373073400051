import apiClient from './basicapi'

const apiRoot = 'contactadmin/'

export function getContactNotificationRecipients() {
  return apiClient.get(`${apiRoot}recipients`).then((res) => res.data)
}

export function postContactNotificationRecipients(recipient) {
  return apiClient.post(`${apiRoot}recipients`, recipient)
}

export function putContactNotificationRecipients(id, recipient) {
  return apiClient.put(`${apiRoot}recipients/${id}`, recipient)
}

export function deleteContactNotificationRecipients(id) {
  return apiClient.delete(`${apiRoot}recipients/${id}`)
}
